import NextLink from 'next/link';

// material-ui
import { ButtonBase, Typography, Box } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
// import LogoMain from './LogoMain';
// import LogoIcon from './LogoIcon';
import { DEFAULT_PATH } from 'config';
import { makeStyles } from '@mui/styles';
// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: string;
  open?: boolean;
}

const useStyles = makeStyles(() => ({
  MainText: {
    fontSize: '15px',
    color: '#007BB5',
    paddingLeft: '15px'
  },
  SubText: {
    fontSize: '13px',
    color: '#999999 ',
    paddingLeft: '15px'
  }
}));

function LogoSection({ reverse, isIcon, sx, to, open }: Props) {
  const truncate = (input: string) => (input?.length > 24 ? `${input.substring(0, 23)}...` : input);
  if (window.location.pathname.includes('signup-wizard')) {
    localStorage.removeItem('loginCompany');
  }
  const loginCompany: any | null = JSON.parse(localStorage.getItem('loginCompany') || '{}');
  const classes = useStyles();
  return (
    <>
      {!window.location.pathname.includes('login') ? (
        <>
          <NextLink href={!to ? DEFAULT_PATH : to} passHref>
            <ButtonBase disableRipple sx={sx}>
              {/* {isIcon ? <LogoIcon /> : <LogoMain reverse={reverse} />} */}
              <img width="48" src="/assets/images/logo/cw-logo.png" />
            </ButtonBase>
          </NextLink>
          {open !== false && (
            <Box>
              <Typography className={classes.MainText}>
                {truncate(loginCompany?.company_name)}
                {truncate(loginCompany?.company?.company_name)}
              </Typography>
              <Typography className={classes.SubText}>
                {truncate(loginCompany ? (loginCompany?.branch_name ? loginCompany?.branch_name : '') : '')}
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default LogoSection;
