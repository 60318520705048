import { useEffect } from 'react';

// next
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

// types
import Loader from 'components/Loader';
import { BASE_PATH } from 'config';

import useLocalStorage from 'hooks/useLocalStorage';
import { useDispatch } from 'react-redux';
import { updateMenuList } from 'store/reducers/menu';
import { GuardProps } from 'types/auth';
// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { data: session, status } = useSession();
  const router = useRouter();
  const dispatch = useDispatch();
  const [menuItems] = useLocalStorage('menu', []);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`${BASE_PATH}/api/auth/protected`);
      const json = await res.json();
      if (!json.protected) {
        router.push('/login');
      }
    };
    fetchData();
    if (!menuItems) {
      router.push('/login');
    }
    if (menuItems.length != 0) dispatch(updateMenuList(menuItems));
    // eslint-disable-next-line
  }, [session]);

  if (status === 'loading' || !session?.user) return <Loader />;

  return children;
};

export default AuthGuard;
