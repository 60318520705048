import { CSSProperties, ReactElement, useEffect, useState } from 'react';

// next
import Link from 'next/link';

// material-ui
import { Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { useTheme } from '@mui/material/styles';

// project imports
import MainCard from '../MainCard';

// assets
import { ApartmentOutlined } from '@ant-design/icons';

// types
import { useSelector } from 'store';
import { NavItemType } from 'types/menu';
import { OverrideIcon, RootStateProps } from 'types/root';

// ==============================|| BREADCRUMBS ||============================== //

export interface BreadCrumbSxProps extends CSSProperties {
  mb?: string;
  bgcolor?: string;
}

interface Props {
  card?: boolean;
  divider?: boolean;
  icon?: boolean;
  icons?: boolean;
  maxItems?: number;
  navigation?: { items: NavItemType[] };
  rightAlign?: boolean;
  separator?: OverrideIcon;
  title?: boolean;
  titleBottom?: boolean;
  sx?: BreadCrumbSxProps;
}

const Item = (p: NavItemType) => {
  if (p.url) {
    return (
      <Link href={p.url ?? ('' as string)} passHref>
        <Typography
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            }
          }}
          variant="subtitle1"
          color="textSecondary"
        >
          {p.title}
        </Typography>
      </Link>
    );
  }

  return (
    <Typography variant="subtitle1" color="textPrimary">
      {p.title}
    </Typography>
  );
};
const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}: Props) => {
  const theme = useTheme();
  const breadcrumb = useSelector((state: RootStateProps) => state.breadcrumb);
  const [main, setMain] = useState<NavItemType | undefined>();
  const [item, setItem] = useState<NavItemType>();
  const [subitems, setSubItems] = useState<NavItemType[]>();

  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '1rem',
    height: '1rem',
    color: theme.palette.secondary.main
  };

  let currentPath = document.location.pathname;

  // only used for component demo breadcrumbs
  if (currentPath === '/components-overview/breadcrumbs') {
    currentPath = '/dashboard/analytics';
  }

  if (currentPath === '/apps/kanban/backlogs') {
    currentPath = '/apps/kanban/board';
  }

  // set active item state
  const getCollapse = (menu: NavItemType) => {
    if (menu.children) {
      menu.children.filter((collapse: NavItemType) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse as { children: NavItemType[]; type?: string });
        } else if (collapse.type && collapse.type === 'item' && collapse.breadcrumbs !== false) {
          if (currentPath.includes(collapse.url!)) {
            setMain(menu);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    setSubItems([]);
    navigation?.items?.map((menu: NavItemType, index: number) => {
      if (menu.type && menu.type === 'group') {
        getCollapse(menu as { children: NavItemType[]; type?: string });
      }
      return false;
    });
  }, [currentPath]);

  useEffect(() => {
    if (breadcrumb.show) {
      setMain(undefined);
      setItem(undefined);
      const subs: NavItemType[] = [];
      breadcrumb.items.forEach((item) => {
        if (item.type === 'collapse') {
          setMain(item);
          currentPath = item?.url ?? '';
        } else if (item.type === 'item') {
          setItem(item);
        } else {
          subs.push(item);
        }
      });
      setSubItems(subs);
    }
  }, [breadcrumb.items]);

  // item separator
  const SeparatorIcon = separator!;
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  let mainContent;
  let itemContent;
  let breadcrumbContent: ReactElement = <Typography />;
  let itemTitle: NavItemType['title'] = '';
  let CollapseIcon;
  let ItemIcon;

  // collapse item
  if (main && main.type === 'collapse') {
    CollapseIcon = main.icon ? main.icon : ApartmentOutlined;
    mainContent = (
      <Link href={breadcrumb.show ? main.url ?? currentPath : currentPath} passHref>
        <Typography
          variant="h6"
          // sx={{ textDecoration: 'none' }}
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            }
          }}
          color="textPrimary"
        >
          {icons && <CollapseIcon style={iconSX} />}
          {main.title}
        </Typography>
      </Link>
    );
  }

  // items
  if (item && item.type === 'item' && item.breadcrumbs !== false) {
    itemTitle = item.title;

    ItemIcon = item.icon ? item.icon : ApartmentOutlined;
    itemContent = (
      <Link href={breadcrumb.show ? item.url ?? currentPath : currentPath} passHref>
        <Typography
          variant="subtitle1"
          sx={{
            '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
            }
          }}
          color={subitems && subitems.length > 0 ? 'textSecondary' : 'textPrimary'}
        >
          {icons && <ItemIcon style={iconSX} />}
          {itemTitle}
        </Typography>
      </Link>
    );

    // main
    breadcrumbContent = (
      <MainCard border={card} sx={card === false ? { bgcolor: 'transparent', ...sx } : { ...sx }} {...others} content={card} shadow="none">
        <Grid
          container
          direction={rightAlign ? 'row' : 'column'}
          justifyContent={rightAlign ? 'space-between' : 'flex-start'}
          alignItems={rightAlign ? 'center' : 'flex-start'}
          spacing={1}
        >
          {title && !titleBottom && (
            <Grid item>
              <Typography variant="h2">{item.title}</Typography>
            </Grid>
          )}
          <Grid item>
            <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
              {mainContent}
              {itemContent}
              {subitems &&
                subitems.map((i, index) => (
                  <Item key={`breadcrumb_item_${index}`} {...i} url={index == subitems.length - 1 ? '' : i.url} />
                ))}
            </MuiBreadcrumbs>
          </Grid>
          {/* {title && titleBottom && (
            <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
              <Typography variant="h2">{item.title}</Typography>
            </Grid>
          )} */}
        </Grid>
        {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
      </MainCard>
    );
  }

  return breadcrumbContent;
};

export default Breadcrumbs;
