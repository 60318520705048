import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Drawer from './Drawer';
import Footer from './Footer';
import Header from './Header';

import useConfig from 'hooks/useConfig';

// types
import { openDrawer } from 'store/reducers/menu';
import { RootStateProps } from 'types/root';

// ==============================|| MAIN LAYOUT ||============================== //

interface Props {
  children: ReactNode;
}

const MainLayoutDashboard = ({ children }: Props) => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('md'));

  const { miniDrawer } = useConfig();
  const dispatch = useDispatch();

  const menu = useSelector((state: RootStateProps) => state.menu);
  // const { drawerOpen } = menu;

  // drawer toggler
  const [open, setOpen] = useState(false); //useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      // setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    console.log(open);
    dispatch(openDrawer({ drawerOpen: open }));
  }, [open]);

  // useEffect(() => {
  //   if (open !== drawerOpen) setOpen(drawerOpen);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [drawerOpen]);

  return (
    <Box
      sx={{
        display: 'flex'
        // , marginTop: '-15px'
      }}
    >
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />

      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 0, sm: 0 }
          // maxWidth: {xl:'calc(100vw - 260px)', md:'calc(100vw - 260px)', sm:'calc(100vw)', xs:'calc(100vw)'}
        }}
      >
        {' '}
        {/* 'calc(100vw - 259px)' */}
        <Toolbar />
        <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
          {children}
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayoutDashboard;
