// material-ui
import { Box, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';

// project import
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { useSelector } from 'react-redux';
import { RootStateProps } from 'types/root';
import MobileSection from './MobileSection';
import Profile from './Profile';
import Search from './Search';

// import Notification from './Notification';

// ==============================|| HEADER - CONTENT ||============================== //
type Iprop = {
  variant?: string;
};
const HeaderContent = ({ variant }: Iprop) => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const menu = useSelector((state: RootStateProps) => state.menu);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {'landing-auth' != variant && !matchesXs && (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs navigation={menu.menus ?? {}} title titleBottom card={false} divider={false} />
        </Box>
      )}
      {!matchesXs && <Search />}
      {/* {!matchesXs && megaMenu} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <Notification /> */}
      {/* <Message /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
