// import { useSelector } from 'react-redux';

// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';

// types
// import { RootStateProps } from 'types/root';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const menu: any | null = JSON.parse(localStorage.getItem('loginMenu') || '{}');
  // const menu = useSelector((state: RootStateProps) => state.menu);
  // console.log(menu)
  const { drawerOpen } = menu;
  const navGroups = menu?.items?.map((item: any) => {
    // menu?.menus?.items?.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  console.log(menu);
  return <Box sx={{ pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>;
};

export default Navigation;
