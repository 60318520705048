import * as Icons from '@ant-design/icons';

type IconProp = {
  name?: any;
  prop?: any;
};
const IconsMenu = ({ name, prop }: IconProp) => {
  if (name === 'HomeOutlined') return <Icons.HomeOutlined {...prop} />;
  if (name === 'DashboardOutlined') return <Icons.DashboardOutlined {...prop} />;
  if (name === 'FileTextOutlined') return <Icons.FileTextOutlined {...prop} />;
  if (name === 'CustomerServiceOutlined') return <Icons.CustomerServiceOutlined {...prop} />;
  if (name === 'CalendarOutlined') return <Icons.CalendarOutlined {...prop} />;
  if (name === 'LineChartOutlined') return <Icons.LineChartOutlined {...prop} />;
  if (name === 'DollarCircleOutlined') return <Icons.DollarCircleOutlined {...prop} />;
  if (name === 'SettingOutlined') return <Icons.SettingOutlined {...prop} />;
  if (name === 'SlidersOutlined') return <Icons.SlidersOutlined {...prop} />;
  if (name === 'TeamOutlined') return <Icons.TeamOutlined {...prop} />;
  if (name === 'OrderedListOutlined') return <Icons.OrderedListOutlined {...prop} />;
  if (name === 'ContactsOutlined') return <Icons.ContactsOutlined {...prop} />;
  if (name === 'SolutionOutlined') return <Icons.SolutionOutlined {...prop} />;
  if (name === 'CarryOutOutlined') return <Icons.CarryOutOutlined {...prop} />;
  if (name === 'MoneyCollectOutlined') return <Icons.MoneyCollectOutlined {...prop} />;
  if (name === 'ShoppingCartOutlined') return <Icons.ShoppingCartOutlined {...prop} />;
  if (name === 'UsergroupDeleteOutlined') return <Icons.UsergroupDeleteOutlined {...prop} />;

  return <></>;
};
export default IconsMenu;
